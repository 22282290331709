<script>
import {
    RipplesThumbnail,
    RipplesPreview,
    SandWallThumbnail,
    RedOfficeThumbnail,
    BlueOfficeThumbnail,
    WoodenArchitectureThumbnail,
    WavesThumbnail,
    PunkThumbnail,
    LayersThumbnail,
    PurplishPreview,
    CarbonPreview,
    RedOfficePreview,
    DefaultPreview,
    BlueOfficePreview,
    SandWallPreview,
    WoodenArchitecturePreview,
    WavesPreview,
    PunkPreview,
    LayersPreview,
    Ripples,
    RedOffice,
    BlueOffice,
    SandWall,
    WoodenArchitecture,
    Waves,
    Punk,
    Layers,
} from '../../images/tv-bg';
import { PopperTooltip } from '@goodvibes/kudoslink';

export const themes = Object.freeze([
    {
        name: 'default',
        value: 'bg-gradient-to-tr from-[rgba(128,220,241,1)] from-40% to-[rgba(70,107,240,1)]',
        isDark: true,
        preview: DefaultPreview,
    },
    { name: 'ripples', value: Ripples, thumbnail: RipplesThumbnail, isDark: false, preview: RipplesPreview },
    {
        name: 'purplish',
        value: 'bg-gradient-to-tr from-[#6212E5] to-[#E31266]',
        isDark: true,
        preview: PurplishPreview,
    },
    { name: 'carbon', value: 'bg-gradient-to-b from-[#1F2934] to-[#2B3B43]', isDark: true, preview: CarbonPreview },
    { name: 'red office', value: RedOffice, thumbnail: RedOfficeThumbnail, isDark: true, preview: RedOfficePreview },
    {
        name: 'blue office',
        value: BlueOffice,
        thumbnail: BlueOfficeThumbnail,
        isDark: true,
        preview: BlueOfficePreview,
    },
    { name: 'sand wall', value: SandWall, thumbnail: SandWallThumbnail, isDark: true, preview: SandWallPreview },
    {
        name: 'wooden architecture',
        value: WoodenArchitecture,
        thumbnail: WoodenArchitectureThumbnail,
        isDark: true,
        preview: WoodenArchitecturePreview,
    },
    { name: 'waves', value: Waves, thumbnail: WavesThumbnail, isDark: true, preview: WavesPreview },
    { name: 'punk', value: Punk, thumbnail: PunkThumbnail, isDark: true, preview: PunkPreview },
    { name: 'layers', value: Layers, thumbnail: LayersThumbnail, isDark: false, preview: LayersPreview },
]);

export const defaultTheme = themes[0].value;

const LUMINANCE_TRESHOLD = 200;

function isImage(theme) {
    return /\.\w+$/.test(theme);
}

function getImageOriginalName(imagePath) {
    const segments = imagePath.split('/');
    const filename = segments[segments.length - 1];
    let [name, ext] = filename.split('.', 2);
    let hash = undefined;
    [name, hash] = name.split('-');
    return `${name}.${ext}`;
}

export function isDarkTheme(theme) {
    if (isImage(theme)) {
        const presetTheme = themes
            .filter(t => isImage(t.value))
            .find(t => getImageOriginalName(t.value) === getImageOriginalName(theme));
        return presetTheme ? presetTheme.isDark : false;
    }

    const presetTheme = themes.find(t => t.value === theme);
    if (presetTheme) return presetTheme.isDark;
    // it must be custom color
    return getLuminance(theme) < LUMINANCE_TRESHOLD;
}

function getLuminance(hexColor) {
    const { r, g, b } = hexToRgb(hexColor);
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance;
}

function hexToRgb(hex) {
    let normalizedHex = hex.replace('#', '');

    // Check if we are using shorthand and convert to full hex if needed
    if (normalizedHex.length === 3) {
        normalizedHex =
            '#' +
            normalizedHex[0] +
            normalizedHex[0] +
            normalizedHex[1] +
            normalizedHex[1] +
            normalizedHex[2] +
            normalizedHex[2];
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizedHex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}
</script>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    selectedTheme: {
        type: String,
        default: () => defaultTheme,
    },
});

const emit = defineEmits(['update:selectedTheme']);

const customTheme = ref(/(^#)|(^rgb)|(^hsl)/.test(props.selectedTheme) ? props.selectedTheme : null);
const isImageTheme = theme => /\..+$/.test(theme);

const isCustomThemeSelected = computed(() => customTheme.value === props.selectedTheme);
</script>

<template>
    <div class="flex gap-10">
        <div class="w-6/12 max-w-lg">
            <label class="input-label">Choose a tv view theme or a custom color</label>
            <div class="flex flex-wrap gap-3">
                <button
                    type="button"
                    class="shrink-0 grow-0"
                    v-for="theme in themes"
                    :key="theme"
                    @click="emit('update:selectedTheme', theme.value)"
                >
                    <PopperTooltip placement="top">
                        <Component
                            :title="theme.name"
                            :is="isImageTheme(theme.value) ? 'img' : 'div'"
                            :src="isImageTheme(theme.value) ? theme.thumbnail : null"
                            :class="{
                                [theme.value]: !isImageTheme(theme.value),
                                'theme-picker__theme--selected': theme.value === selectedTheme,
                            }"
                            class="theme-picker__theme"
                        />
                        <template #popper>
                            <img
                                :src="theme.preview"
                                :alt="`'${theme.name}' preview`"
                                class="max-w-[320px] aspect-video object-cover rounded-md"
                            />
                        </template>
                    </PopperTooltip>
                </button>
            </div>
        </div>
        <div class="grow">
            <label class="input-label">Custom color</label>
            <button type="button" class="block">
                <label class="cursor-pointer">
                    <div
                        @click="customTheme && emit('update:selectedTheme', customTheme)"
                        class="theme-picker__theme relative"
                        :class="{ 'theme-picker__theme--selected': isCustomThemeSelected }"
                        :style="{
                            background: customTheme ?? 'linear-gradient(225deg, #FD0000 0%, #FC6EED 50.06%, #0FF 100%)',
                        }"
                    >
                        <div v-if="!customTheme" class="absolute inset-0 flex items-center justify-center gap-x-1">
                            <span class="w-1 aspect-square bg-white rounded-full"></span>
                            <span class="w-1 aspect-square bg-white rounded-full"></span>
                            <span class="w-1 aspect-square bg-white rounded-full"></span>
                        </div>
                    </div>
                    <input
                        v-model="customTheme"
                        @input="emit('update:selectedTheme', $event.target.value)"
                        type="color"
                        class="invisible absolute -translate-y-full"
                    />
                </label>
            </button>
        </div>
    </div>
</template>
<style>
.v-popper--theme-dropdown .v-popper__inner {
    background: #ffffff;
    color: #3f4a55;
    padding: 24px;
    border-radius: 18px;
    box-shadow: 0px 4px 20px #e4edf5;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
    border-color: #ffffff;
}

.theme-picker__theme {
    @apply object-cover w-16 aspect-square rounded-full transition-all ease-in-out duration-200;
    border: 2px solid white;
    box-shadow: 0 0 0 2px #eaf0f5;
}

.theme-picker__theme--selected {
    border: 2px solid white;
    box-shadow: 0 0 0 2px #1212e0;
}
</style>
