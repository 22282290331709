import { PollTypes } from './shared';

export const Meeting = {
    /**
     *
     * @param {RawMeeting} m
     * @return {Meeting}
     */
    format(m) {
        return {
            // ...m,
            id: m.id,
            uuid: m.uuid,
            encryptedMeetingUuid: getProperty(m, 'encryptedMeetingUuid'),
            joiningCode: getProperty(m, 'joiningCode'),
            name: m.name,
            ...getTimestamps(m),
            logo: m.logo,
            theme: m.theme,
            moderatorUrl: getProperty(m, 'moderatorUrl'),
        };
    },
};

export const Poll = {
    /**
     *
     * @param {RawPoll} p
     * @return {Poll}
     */
    format(p) {
        return {
            // ...p,
            id: p.id,
            type: p.type,
            topic: p.topic,
            description: p.description,
            ...getTimestamps(p),
            choices: p.choices,
            settings: p.settings,
            closedAt: getDate(p, 'closedAt'),
            showResults: getProperty(p, 'showResults'),
            responsesCount: getProperty(p, 'responsesCount'),
            lockUrl: getProperty(p, 'lockUrl'),
            unlockUrl: getProperty(p, 'unlockUrl'),
            showResultsUrl: getProperty(p, 'showResultsUrl'),
            hideResultsUrl: getProperty(p, 'hideResultsUrl'),
            highlightedResponseId: getProperty(p, 'highlightedResponseId'),
            highlightResponseUrl: getProperty(p, 'highlightResponseUrl'),
            removeHighlightResponseUrl: getProperty(p, 'removeHighlightResponseUrl'),
        };
    },

    // TODO: this instead of poll
    isResponseHighlighted(response, poll) {
        return response.id === poll.highlightedResponseId;
    },
};

export const MultipleChoice = {
    /**
     *
     * @param {RawMultipleChoice} mc
     * @return {MultipleChoice}
     */
    format(mc) {
        return {
            // ...mc,
            id: mc.id,
            answers: mc.answers,
            anonymousId: getProperty(mc, 'anonymousId'),
            ...getTimestamps(mc),
        };
    },
};

export const Ranking = {
    /**
     *
     * @param {RawRanking} r
     * @return {Ranking}
     */
    format(r) {
        return {
            // ...r,
            id: r.id,
            answers: r.answers,
            anonymousId: getProperty(r, 'anonymousId'),
            ...getTimestamps(r),
        };
    },
};

export const Question = {
    /**
     *
     * @param {RawQuestion} q
     * @return {Question}
     */
    format(q) {
        return {
            // ...q,
            id: q.id,
            name: q.name,
            message: q.message,
            anonymousId: getProperty(q, 'anonymousId'),
            approvedAt: getDate(q, 'approvedAt'),
            answeredAt: getDate(q, 'answeredAt'),
            deletedAt: getDate(q, 'deletedAt'),
            ...getTimestamps(q),
            markAnsweredUrl: getProperty(q, 'markAnsweredUrl'),
            approveUrl: getProperty(q, 'approveUrl'),
            denyUrl: getProperty(q, 'denyUrl'),
            upvotes: q.upvotes?.map(Upvote.format) ?? [],
        };
    },

    /**
     * Check whether the question was upvoted by given user
     * @param {Question} question
     * @param {string} anonymousId
     */
    isUpvotedBy(question, anonymousId) {
        if (question.upvotes === undefined) return undefined;
        return question.upvotes.some(upvote => upvote.anonymousId === anonymousId);
    },

    /**
     *
     * @param {Question} question
     * @returns {boolean}
     */
    isPending(question) {
        return !question.answeredAt && !question.approvedAt;
    },

    /**
     * @param {Array<Question>} questions
     * @param {keyof Question} by
     * @param {'asc' | 'desc'} direction
     * @param {function(Question): boolean}
     */
    sort(questions, by = 'createdAt', direction = 'desc', isHighlighted = q => false) {
        const orderBy = by;
        const orderBy2 = by === 'createdAt' ? 'upvotes' : 'createdAt';

        // crate a copy of the questions, because sort mutates the original array
        const results = [...questions].sort((q1, q2) => {
            // highest priority, if the questions is highlighted it's first, we don't care about the rest
            if (isHighlighted(q1)) return -1;
            if (isHighlighted(q2)) return 1;

            // if the props we comapare are arrays, we use the array length property for comparison
            const result =
                (Array.isArray(q2[orderBy]) ? q2[orderBy].length : q2[orderBy]) -
                (Array.isArray(q1[orderBy]) ? q1[orderBy].length : q1[orderBy]);

            // if 2 questions should have same position, use the orderBy2 to find which one should be first
            return result !== 0
                ? result
                : (Array.isArray(q2[orderBy2]) ? q2[orderBy2].length : q2[orderBy2]) -
                      (Array.isArray(q1[orderBy2]) ? q1[orderBy2].length : q1[orderBy2]);
        });

        // by default we sort it in descending order
        // if it should be asc, just reverse the array
        return direction === 'desc' ? results : results.reverse();
    },

    /**
     * @param {Array<Question>} questions
     * @param {'asc' | 'desc'} direction
     * @param {function(Question): boolean}
     */
    sortByPopularity(questions, direction = 'desc', isHighlighted = q => false) {
        return this.sort(questions, 'upvotes', direction, isHighlighted);
    },
};

export const Rating = {
    /**
     *
     * @param {RawRating} rating
     * @returns {Rating}
     */
    format(r) {
        return {
            // ...r,
            id: r.id,
            rating: r.rating,
            anonymousId: getProperty(r, 'anonymousId'),
            ...getTimestamps(r),
        };
    },
};

export const WordCloud = {
    /**
     *
     * @param {RawWordCloud} rating
     * @returns {WordCloud}
     */
    format(w) {
        return {
            // ...w,
            word: w.word,
            wordCount: getProperty(w, 'wordCount'),
        };
    },
};

export const OpenText = {
    /**
     *
     * @param {RawOpenText} o
     * @return {OpenText}
     */
    format(o) {
        return {
            // ...o,
            id: o.id,
            name: o.name,
            message: o.message,
            anonymousId: getProperty(o, 'anonymousId'),
            ...getTimestamps(o),
            deletedAt: getDate(o, 'deletedAt'),
        };
    },

    /**
     * @param {Array<OpenText>} openTexts
     * @param {keyof OpenText} by
     * @param {'asc' | 'desc'} direction
     * @param {function(OpenText): boolean}
     */
    sort(openTexts, by = 'createdAt', direction = 'desc') {
        const orderBy = by;

        // crate a copy of the openTexts, because sort mutates the original array
        const results = [...openTexts].sort((o1, o2) => {
            // if the props we comapare are arrays, we use the array length property for comparison
            const result =
                (Array.isArray(o2[orderBy]) ? o2[orderBy].length : o2[orderBy]) -
                (Array.isArray(o1[orderBy]) ? o1[orderBy].length : o1[orderBy]);
            return result;
        });

        // by default we sort it in descending order
        // if it should be asc, just reverse the array
        return direction === 'desc' ? results : results.reverse();
    },
};

export const Upvote = {
    /**
     *
     * @param {RawUpvote} u
     * @returns {Upvote}
     */
    format(u) {
        return {
            // ...u,
            id: u.id,
            anonymousId: getProperty(u, 'anonymousId'),
            ...getTimestamps(u),
        };
    },
};

export const Response = {
    /**
     *
     * @param {RawPollResponse} r
     * @param {PollType} type
     * @returns {PollResponse}
     */
    format(r, type) {
        if (type === PollTypes.QA) return Question.format(r);
        if (type === PollTypes.MULTIPLE_CHOICE) return MultipleChoice.format(r);
        if (type === PollTypes.RATING) return Rating.format(r);
        if (type === PollTypes.WORD_CLOUD) return WordCloud.format(r);
        if (type === PollTypes.OPEN_TEXT) return OpenText.format(r);
        if (type === PollTypes.RANKING) return Ranking.format(r);

        throw new Error(`Can't format unrecognized response type "${type}"`);
    },
};

/**
 *
 * @param {RawPollResponse} model
 * @returns {{ createdAt: Date, updatedAt: Date }}
 */
function getTimestamps(model) {
    return {
        createdAt: getDate(model, 'createdAt'),
        updatedAt: getDate(model, 'updatedAt'),
    };
}

/**
 *
 * @param {RawPollResponse} model
 * @param {string} property
 * @returns {Date|null}
 */
function getDate(model, property) {
    const value = getProperty(model, property);
    return value ? new Date(value) : null;
}

/**
 *
 * @param {RawPollResponse} model
 * @param {string} property
 */
function getProperty(model, property) {
    const camelName = toCamelCase(property);
    const snakeName = toSnakeCase(property);
    return model[camelName] ?? model[snakeName];
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
function toCamelCase(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
function toSnakeCase(str) {
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_');
}
